import React from 'react'
import sanityClient from '@sanity/client'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import LoginHoc from '../hocs/LoginHoc'
import ExhibitorSelection from '../components/ExhibitorSelection'

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_SANITY_TOKEN,
  useCdn: false // `false` if you want to ensure fresh data
})

const titleTypeConfig = {
  new: {
    seo: 'New Booth | Afwerx Space Challenge Virtual Showcase',
    hero: 'New Booth'
  },
  edit: {
    seo: 'Edit Booth | Afwerx Space Challenge Virtual Showcase',
    hero: 'Edit Booth'
  },
  selection: {
    seo: 'Booth Management | Afwerx Space Challenge Virtual Showcase',
    hero: 'Booth Management'
  }
}

export default props => {
  const [titleType, setTitleType] = React.useState('selection')

  const setIsNewExhibitorState = React.useCallback(
    user => {
      if (user.exhibitor != null && user.exhibitor._id != null) {
        setTitleType('edit')
        return
      }

      if (
        Array.isArray(user.exhibitors) &&
        user.exhibitors.length > 0 &&
        !user.exhibitor
      ) {
        setTitleType('selection')
        return
      }

      setTitleType('new')
    },
    [titleType]
  )

  return (
    <Layout>
      <SEO title={titleTypeConfig[titleType].seo} />
      <Hero
        title={titleTypeConfig[titleType].hero}
        className="exhibitor-page"
        img={require('../assets/imgs/stars.jpg')}
      />
      <LoginHoc
        sanityClient={client}
        onSuccessfulAuthentication={setIsNewExhibitorState}
        onSignOut={() => setTitleType('new')}
        pagePath={props.path}
      >
        <section className="container intro-text">
          <p className="text--center home-page-intro__title">
            Welcome to Booth Registration!
          </p>
        </section>
        <ExhibitorSelection
          client={client}
          onSuccessfullFirstExhibitorCreation={setIsNewExhibitorState}
        />
      </LoginHoc>
    </Layout>
  )
}
